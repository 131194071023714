@import "../../styles/index.scss";

._title {
  font-family: Helvetica, Arial, sans-serif;
  color: #f7f7f7;
  font-size: 1rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

._container {
  width: 100%;
  max-width: 55.0625rem;
  margin: 0 auto;
  padding: 2rem 3rem;
}
._headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;
}

._button {
  border-radius: 0.25rem;
  border: 2px solid #040e2f;
  background: #ed8989;
  padding: 0.375rem 0.5rem;
  color: #000;
  font-family: Arial;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: unset;
  &:hover {
    border: 2px solid #50ac3f;
    background: #000;
    color: #ed8989;
  }
}

._content {
  display: grid;
  grid-template-columns: repeat(auto-fill, 7.76042rem);
  gap: 0.5rem;
  padding-top: 1rem;
}
