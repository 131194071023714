@import "../../styles/index.scss";

._title {
  color: #f7f7f7;
  text-align: center;
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* heading/h4 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h4, 1.75rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h4, 2.75rem); /* 157.143% */
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

._swiper {
  width: 100%;
  position: relative;
}

._arrowContainer {
  position: absolute;
  top: 0;
  left: 2rem;
  bottom: 0;
  height: 32px;
  width: 32px;
  margin: auto 0;
  z-index: 10;
  box-sizing: border-box;
}
._arrowLeft {
  right: 2rem;
  left: unset;
}
._slide {
  width: 100% !important;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 70vw;
  margin: 0 auto;
}

@include responsive(lg) {
  ._arrowContainer {
    top: unset;
    bottom: 0rem;
    left: 1vw;
  }

  ._arrowLeft {
    right: 1vw;
    left: unset;
  }

  ._title {
    color: #83bf4f;
    text-align: center;
    font-family: "Denk One";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}
