@import "../../styles/index.scss";

._container {
  grid-template-columns: 156px auto 243px;
}

._floatingAd {
  display: none;
}
._toggleButton {
  display: none;
}

._logoContainer {
  display: none;
}

@include responsive(xl) {
  ._container {
    grid-template-columns: 156px auto;
  }
  ._adBar {
    display: none;
  }

  ._floatingAd {
    display: block;
    position: fixed;
    bottom: 0.5rem;
    right: 0.5rem;
    width: 243px;
    height: 178px;
    z-index: 100;
    background-color: #040e2f;
  }
}

@media screen and (max-width: 1200px) {
  ._logoContainer {
    display: block;
    padding-left: 0.5rem;
  }

  ._toggleButton {
    display: block;
  }

  ._container {
    grid-template-columns: 45px auto;
  }
}


