@use "sass:map";

@mixin responsive($size) {
  @if $size == xl {
    @media (max-width: 1280px) {
      @content;
    }
  }
  @if $size == lg {
    @media (max-width: 1024px) {
      @content;
    }
  }

  @if $size == md {
    @media (max-width: 700px) {
      @content;
    }
  }

  @if $size == sm {
    @media (max-width: 450px) {
      @content;
    }
  }

  @if $size == xs {
    @media (max-width: 360px) {
      @content;
    }
  }
}

@mixin titles($font, $size, $color, $spacing: 0.01em, $weight: 600) {
  font-family: $font;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  /* identical to box height, or 73px */
  letter-spacing: $spacing;
  color: $color;
  leading-trim: both;
  text-edge: cap;
}

@mixin subtitle() {
  @include titles($secondaryFont, $sizeSubtitle, $textBlack, 1%, 500);
}

@mixin paragraph($size, $color, $weight: 400, $spacing: 0.02em) {
  font-family: $quaternaryFont;
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  /* identical to box height, or 73px */
  letter-spacing: $spacing;
  color: $color;
  leading-trim: both;
  text-edge: cap;
}
@mixin H1() {
  @include titles($primaryFont, $sizeH1, $textBlack, normal, 700);
}

@mixin H2() {
  @include titles($primaryFont, $sizeH2, $textBlack, normal, 700);
}
@mixin H3() {
  @include titles($primaryFont, $sizeH3, $textBlack, 0.01375rem, 600);
}

@mixin strongBody() {
  @include paragraph($sizeStrongBody, $textBlack, 700, 0%);
}

@mixin body() {
  @include paragraph($sizeBody, $textBlack, 400, 0%);
}

@mixin strongSmallBody() {
  @include paragraph($sizeStrongSmallBody, $textBlack, 600, 1%);
}

@mixin smallBody() {
  @include paragraph($sizeSmallBody, $textBlack, 400, 1%);
}

@mixin strongExtraSmallBody() {
  @include paragraph($sizeStrongExtraSmallBody, $textBlack, 700, 2%);
}

@mixin extraSmallBody() {
  @include paragraph($sizeExtraSmallBody, $textBlack, 500, 2%);
}

@mixin hyperlinks() {
  @include paragraph($sizeHyperlinks, $textBlack, 500, 2%);
}

@mixin navigation() {
  @include paragraph($navSize, $textBlack, 400, normal);
}

@mixin footerText() {
  @include paragraph($footerSize, $textBlack, 400, normal);
}

@mixin Input() {
  @include smallBody();
  box-sizing: border-box;
  border-radius: 0.3125rem;
  background: #fff;
  border: none;
  color: $primary90;
  border-radius: 5px;
  width: 100%;
  padding: 0.69rem 1.25rem;
  outline: none;
  &::placeholder {
    color: $primary90;
    opacity: 1;
  }
}

@mixin Button($background) {
  border-radius: 0.3125rem;
  background: $background;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.13);
  text-align: center;
  padding: 0.6rem 1.875rem;
  border: none;
  cursor: pointer;
}

@mixin ContactButton() {
  @include Button($secondary10);
  color: $white;
  font-size: 1.25rem;
  font-style: normal;
  font-family: $primaryFont;
  font-weight: 700;
  letter-spacing: 0.06rem;
  cursor: pointer;
  border-radius: 0.75rem;
  border: 2px solid  $secondary10;
  background: linear-gradient(93deg, #125c70 20.19%, #058bb0 92.72%);
  box-shadow: none;
  &:hover {
    background: #058BB0;
  }
}


@mixin primaryButton() {
  @include Button($secondary);
  @include titles($primaryFont, $buttonSize, $textBlack, normal, 600);
  line-height: 1.875rem;
}

@mixin secondaryButton() {
  @include Button($primary10);
  background: none;
  box-shadow: none;
  @include titles($secondaryFont, $sizeSmallBody, $primary90, 1%, 600);
  border: 0.0625rem solid $primary90;
}

@mixin cancelButton() {
  @include Button($error75);
  @include titles($secondaryFont, $sizeSmallBody, $primary10, 1%, 600);
}

@mixin disabledButton() {
  @include Button($primary30);
  @include titles($secondaryFont, $sizeSmallBody, $primary75, 1%, 600);
}

@mixin onlyTextButton() {
  @include Button(none);
  @include titles($secondaryFont, $sizeSmallBody, $primary90, 1%, 600);
}

@mixin tagButton() {
  @include Button($primary30);
  @include titles($secondaryFont, $sizeSmallBody, $primary90, 1%, 600);
  border: 0.0625rem solid $primary75;
}

@mixin seemoreButton() {
  @include Button($primary90);
  @include titles($secondaryFont, $sizeH3, $primary10, 1%, 400);
}

@mixin menuButton($color) {
  @include Button($color);
  @include titles($secondaryFont, $sizeSmallBody, $textBlack, 1%, 600);
}

@mixin gridLayout() {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  box-sizing: border-box;
  width: 100%;
  padding: 0 5rem;
  gap: 1.25rem;
}

@mixin mobileGridLayout() {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  box-sizing: border-box;
  width: 100%;
  padding: 0 1.875rem;
  gap: 1.25rem;
}

@mixin imageBox($width, $height, $space: cover) {
  width: $width;
  height: $height;
  min-width: $width;
  position: relative;
  img {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    object-fit: $space;
  }
}

@mixin shadowInferior($color) {
  box-shadow: 0 0.25rem 0.625rem rgba($color, 0.1);
}

@mixin shadowInferiorSmall($color) {
  box-shadow: 0 0.0625rem 0.4375rem rgba($color, 0.1);
}

@mixin shadowSuperior($color) {
  box-shadow: 0 -0.3125rem 0.625rem rgba($color, 0.1);
}
