@import "../../styles/index.scss";

._container {
  padding-top: 186px;
}

@include responsive(lg) {
  ._container {
    padding-top: 64px;
  }
}
