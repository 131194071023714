@import "../../../../styles/index.scss";

._container2 {
  width: 100%;
  padding: 4rem 0rem;
  background: #252e4b;
}

._content2 {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
}

._title2 {
  color: #83bf4f;
  text-align: center;
  font-family: "Denk One";
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
._itemContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 4rem;
}

._item {
  width: 100%;
  max-width: 21.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._itemImageContainer {
  width: 100%;
  height: 5.1875rem;
  position: relative;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}

._title3 {
  color: var(--text-primary, #f7f7f8);
  text-align: center;
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* heading/h4 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h4, 1.75rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h4, 2.75rem); /* 157.143% */
  text-transform: uppercase;
}

._itemText {
  color: var(--text-primary, #f7f7f8);
  text-align: center;

  /* body/lg */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-lg, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-body-lg, 1.75rem); /* 155.556% */
  margin-top: 0.5rem;
}

._image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}

@include responsive(lg) {
  ._container2 {
    padding: 3rem 1rem;
  }
  ._itemContainer {
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  ._title2 {
    font-size: 2.25rem;
  }

  ._title3 {
    font-size: 1.5rem;
  }

  ._itemText {
    font-size: 1.25rem;
  }
}
