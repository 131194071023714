@import "../../../../styles/index.scss";

._title4 {
  color: var(--primary-Default, #8fd511);
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* heading/h1 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h1, 3rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h1, 4.75rem); /* 158.333% */
  text-transform: uppercase;
  margin-bottom: 1rem;
}

._container3 {
  width: 100%;
  padding: 8rem 0rem 6rem 0rem;
  background: #040e2f;
}

._text4 {
  color: var(--text-primary, #f7f7f8);
  /* body/lg */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-lg, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-body-lg, 1.75rem); /* 155.556% */
}
._textContainer3 {
  width: 100%;
}

._content3 {
  max-width: 75rem;
  width: 100%;
  margin: 0 auto;
  display: grid;
  padding: 0rem 2rem;
  //gap: 4rem;
  column-gap: 4rem;
  grid-template-columns: 0.78fr 1.22fr;
  overflow: hidden;
}

._sliderContainer {
  width: 100%;
}

._swiper {
  width: 100%;
  max-width: 43.3rem !important;
}

._slide {
  width: 100%;
  max-width: 43.3rem !important;
}

._slider {
  display: grid;
  padding: var(--spacing-lg, 1.5rem);
  align-items: center;
  gap: 1.5rem;
  align-self: stretch;
  grid-template-columns: 1.25fr 0.75fr;
  max-width: 43.3rem;
  border-radius: var(--border-radius-md, 0.5rem);
  background: var(--surface-secondary, #0a2e5f);
}

._titleSlider {
  color: var(--primary-300, #bce670);
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* heading/h3 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h3, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h3, 3.25rem); /* 162.5% */
  text-transform: uppercase;
}
._textSlider {
  color: var(--text-primary, #f7f7f8);

  /* body/lg */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-lg, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-body-lg, 1.75rem); /* 155.556% */
}
._imageContainer {
  width: 15rem;
  height: 15rem;
  position: relative;
}

._image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
._textSliderMobile {
  display: none;
}
@include responsive(xl) {
  ._swiper {
    max-width: 34rem !important;
  }
  ._slide {
    max-width: 34rem !important;
  }
}

@include responsive(lg) {
  ._container3 {
    padding: 3.5rem 1rem 3.5rem 1rem;
    max-width: 100vw;
  }
  ._content3 {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  ._title4 {
    font-size: 2.25rem;
    text-align: center;
    line-height: normal;
  }

  ._text4 {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  ._sliderContainer {
    width: 100%;
    max-width: 100%;
  }

  ._swiper {
    width: 100% !important;
    max-width: 100% !important;
  }
  ._slide {
    width: 100% !important;
    max-width: 100% !important;
  }
  ._slider {
    display: flex;
    max-width: 100% !important;
    flex-direction: column;
    align-items: center;
  }

  ._sliderTextContainer {
    text-align: center;
    max-width: 100%;
  }
  ._textSlider {
    display: none;
  }

  ._textSliderMobile {
    display: block;
    color: var(--text-primary, #f7f7f8);
    /* body/lg */
    font-family: var(--type-font-family-secondary, "Exo 2");
    font-size: var(--fontsize-body-lg, 1rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal; /* 155.556% */
    text-align: center;
  }
}
