@import "../../../styles/index.scss";

._container {
  min-height: 600px;
  width: 100%;
  display: grid;
  position: relative;
  overflow: hidden;
  background: #101957;
}

._swiper {
  width: 100%;
  height: 100%;
}

@include responsive(lg) {
  ._container {
    min-height: 300px;
  }
}