@import "../../styles/index.scss";

._container {
  max-width: 55.0625rem;
  width: 100%;
  margin: 0 auto;
  padding: 1rem 3rem;
  padding-bottom: 3rem;
}
._gameContainer {
  width: 100%;
  height: 27.597656246rem;
  & div {
    & canvas {
      width: 100% !important;
      height: 100% !important;
      margin: 0 auto !important;
      object-fit: contain !important;
    }
  }
}

._iframe{
  width: 100%;
  height: 100%;
  border: none;
}
._gameInfo {
  width: 100%;
  background: #1f2742;
  padding: 0.375rem 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

._likes {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: center;
  cursor: pointer;
}

._likesText {
  color: rgba(218, 218, 218, 0.8);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}

._adContainer {
  width: 100%;
  max-width: 55.0625rem;
  margin: 0 auto;
  padding: 1rem 3rem;
}

._gameData {
  width: 100%;
  max-width: 55.0625rem;
  margin: 0 auto;
  padding: 2rem 3rem;
}

._title {
  color: #f7f7f7;
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* heading/h5 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h5, 1.5rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h5, 2.25rem); /* 150% */
  text-transform: uppercase;
}

._infoTitle {
  color: var(--text-secondary, #a0a2ae);

  /* body/sm */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-sm, 0.875rem);
  font-style: normal;
  font-weight: 700;
  line-height: var(--line-height-body-sm, 1.25rem); /* 142.857% */
}

._infoText {
  color: var(--text-primary, #f7f7f8);

  /* body/md */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-md, 1rem);
  font-style: normal;
  font-weight: 400;
}

._infoBox {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

._infoTextContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

._title2 {
  color: #f7f7f7;
  text-align: center;
  font-family: "Exo 2";
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

._tab {
  color: var(--secondary-200, #a0a2ae);

  /* body/md */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-md, 1rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-body-sm, 125%); /* 125% */
}

@include responsive(lg) {
  ._container {
    max-width: calc(100vw - 45px) !important;
    padding: 1rem 1.5rem;
  }
  ._gameContainer {
    height: calc(60.48vw - 5.8125rem);
  }
  ._adContainer {
    padding: 1rem 1.5rem;
  }
  ._gameData {
    padding: 2rem 1.5rem;
  }

  ._title {
    color: #f7f7f7;
    font-family: "Denk One";
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  ._infoTitle {
    color: rgba(247, 247, 247, 0.5);
    font-family: "Exo 2";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  ._infoText {
    overflow: hidden;
    color: #e3e3e3;
    text-overflow: ellipsis;
    font-family: "Exo 2";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
  ._tab {
    color: var(--secondary-200, #a0a2ae);
    font-family: "Exo 2";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 171.429% */
    letter-spacing: 0.025rem;
  }
}
