.swiper-pagination-bullet {
  display: flex !important;
  width: var(--line-height-heading-h4, 2.75rem) !important;
  height: var(--line-height-heading-h4, 2.75rem) !important;
  padding: 0.625rem !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.625rem !important;
  border-radius: 2rem !important;
  background: #252e4b !important;
  color: #fff !important;
  text-align: center !important;
  font-family: "Denk One" !important;
  font-size: 1.5rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

.swiper-pagination-bullet-active {
  border-radius: 2rem !important;
  border: 2px solid #904500 !important;
  background: #ffb74d !important;
  color: #303030 !important;
}

.swiper-pagination {
  display: flex;
  position: relative !important;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.5rem;
  margin-top: 2rem;
}

@media screen and (max-width: 1024px) {
  .swiper-pagination-bullet {
    width: var(--line-height-heading-h4, 1rem) !important;
    height: var(--line-height-heading-h4, 1rem) !important;
    font-size: 1rem !important;
  }
}
