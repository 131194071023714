@import "../../../../styles/index.scss";

._container {
  width: 100%;
  padding: 3rem 0rem;
  background: #040e2f;
}

._title {
  color: var(--primary-Default, #8fd511);
  text-align: center;
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* heading/h1 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h1, 3rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h1, 4.75rem); /* 158.333% */
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

._slide {
  width: 14.3125rem !important;
  height: 5.5625rem;
  flex-shrink: 0;
}

._imageContainer {
  width: 14.3125rem;
  height: 5.5625rem;
  position: relative;
}

._image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}

._logosContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@include responsive(lg) {
  ._container {
    padding: 3rem 0rem;
    max-width: 100vw !important;
  }
  ._title {
    font-size: 2.25rem;
  }

  ._imageContainer {
    width: 9.54125rem;
    height: 3.708333333rem;
    position: relative;
  }
}
