@import "../../../../styles/index.scss";

._container {
  width: 100%;
  padding: 6rem 2.5rem;
  background: #040e2f;
}

._content {
  width: 100%;
  max-width: 56.8rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 3.5rem;
  height: 100%;
}
._imageContainer2 {
  position: relative;
  width: 100%;
  max-width: 16.25rem;
  height: 100%;
  min-height: 15.25rem;
}

._image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}

._title {
  color: var(--primary-Default, #8fd511);
  font-feature-settings:
    "liga" off,
    "clig" off;
  /* heading/h1 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h1, 3rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h1, 4.75rem); /* 158.333% */
  text-transform: uppercase;
}
._text {
  color: var(--text-primary, #f7f7f8);
  /* body/lg */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-lg, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-body-lg, 1.75rem); /* 155.556% */
}
._text {
  margin-top: 1rem;
}

._text2 {
  margin-top: 1rem;
}

._label {
  display: flex;
  padding: var(--spacing-sm, 0.75rem) var(--spacing-md, 1rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-md, 0.5rem);
  background: var(--surface-action, #bce670);
  color: var(--text-on-action, #151519);

  /* button/md */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-md, 1rem);
  font-style: normal;
  font-weight: 600;
  line-height: var(--fontsize-body-md, 1rem); /* 100% */
  text-transform: uppercase;
  margin-top: 2rem;
}

._textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

._titleMobile {
  display: none;
}

@include responsive(lg) {
  ._container{
    padding: 3rem 1rem;
  }
  ._title {
    display: none;
  }

  ._titleMobile {
    color: #83bf4f;
    font-family: "Denk One";
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 3rem; /* 133.333% */
    text-transform: uppercase;
    display: block;
  }
  ._content {
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 2rem;
  }

  ._text {
    color: #fff;
    font-family: "Exo 2";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  ._textContainer {
    width: 100%;
    align-items: center;
  }
}
