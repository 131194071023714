@import "../../../../styles/index.scss";

._container {
  width: 100%;
  padding: 4rem 2.5rem;
  background: #040e2f;
}

._content {
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
}

._title {
  color: var(--primary-Default, #8fd511);
  text-align: center;
  font-family: "Denk One";
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
}

._slide {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

._imageContainer {
  width: 23.875rem;
  height: 23.875rem;
  min-height: 23.875rem;
  min-width: 23.875rem;
  position: relative;
}

._image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}
._textContainer {
  width: 100%;
  max-width: 37rem;
}

._titleSlider {
  color: var(--primary-300, #bce670);
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* heading/h3 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h3, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h3, 3.25rem); /* 162.5% */
  text-transform: uppercase;
}

._textSlider {
  color: var(--text-primary, #f7f7f8);

  /* body/lg */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-lg, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-body-lg, 1.75rem); /* 155.556% */
}

@include responsive(xl) {
  ._content {
    max-width: 59rem;
  }
  ._slideContainer {
    width: 100% !important;
  }
  ._textContainer {
    max-width: 25rem;
  }
  ._swiper {
    width: 100% !important;
  }
}

@include responsive(lg) {
  ._container {
    padding: 3rem 1rem;
    max-width: 100vw !important;
  }
  ._content {
    max-width: 100% !important;
  }
  ._slide {
    flex-direction: column-reverse;
    max-width: 100%;
  }
  ._textContainer {
    max-width: 100%;
    text-align: center;
  }

  ._titleSlider {
    font-size: 2rem;
  }

  ._textSlider {
    font-size: 1rem;
  }

  ._imageContainer {
    width: 100%;
    min-width: 100%;
    max-width: 23.875rem;
  }
}
