@import "../../styles/index.scss";
._imageContainer {
  width: 100%;
  max-width: 14.7875rem;
  height: 5.875rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
._image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}

._content {
  width: 100%;
  padding: 0.5rem 0.25rem 0.75rem 0.25rem;
}
._title {
  margin: 0;
  font-size: 0.875rem;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  color: #ffffff;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
