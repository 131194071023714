@import "../../../../styles/index.scss";

._container {
  width: 100%;
  padding: 7rem 2.5rem;
  padding-left: 0rem;
  background: #040e2f;
}

._content {
  width: 100%;
  max-width: 41.8rem;
  margin: 0 auto;
}

._title {
  color: var(--primary-Default, #8fd511);
  text-align: center;
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* heading/h1 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h1, 3rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h1, 4.75rem); /* 158.333% */
  text-transform: uppercase;
}

._text {
  color: #fff;
  text-align: center;

  /* body/lg */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-lg, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-body-lg, 1.75rem); /* 155.556% */
  span {
    color: #83bf4f;

    /* body/lg-hyperlink */
    font-family: var(--type-font-family-secondary, "Exo 2");
    font-size: var(--fontsize-body-lg, 1.125rem);
    font-style: normal;
    font-weight: 600;
    line-height: var(--line-height-body-lg, 1.75rem);
    text-decoration-line: underline;
  }
  margin-top: 1rem;
}

._button {
  display: flex;
  padding: var(--spacing-sm, 0.75rem) var(--spacing-md, 1rem);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-md, 0.5rem);
  background: var(--surface-action, #bce670);
  color: var(--text-on-action, #151519);

  /* button/md */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-md, 1rem);
  font-style: normal;
  font-weight: 600;
  line-height: var(--fontsize-body-md, 1rem); /* 100% */
  text-transform: uppercase;
  margin-top: 2rem;
}

._imageContainer {
  width: 26.25rem;
  height: 20.25rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  position: relative;
}

._image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
}

@include responsive(lg) {
  ._container {
    padding: 3rem 1rem;
  }

  ._title {
    font-size: 2.25rem;
  }

  ._text {
    font-size: 1rem;
  }

  ._imageContainer {
    width: 100%;
    max-width: 20rem;
  }
}
