@import "../../../../styles/index.scss";

._container {
  width: 100%;
  padding: 4rem 2.5rem;
  padding-left: 0rem;
  background: #040e2f;
}
._content {
  max-width: 77.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}

._imageContainer {
  width: 37.3rem;
  height: 35.8rem;
  position: relative;
}

._image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

._textContainer {
  width: 100%;
  max-width: 37.3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

._title {
  color: var(--primary-Default, #8fd511);
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* heading/h1 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h1, 3rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h1, 4.75rem); /* 158.333% */
  text-transform: uppercase;
}

._text {
  color: #fff;

  /* body/lg */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-lg, 1.125rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-body-lg, 1.75rem); /* 155.556% */
  margin-top: 1rem;
}

._textAction {
  color: var(--primary-Default, #8fd511);
  font-feature-settings:
    "liga" off,
    "clig" off;

  /* heading/h5 */
  font-family: var(--type-font-family-primary, "Denk One");
  font-size: var(--fontsize-heading-h5, 1.5rem);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-heading-h5, 2.25rem); /* 150% */
  text-transform: uppercase;
  margin-top: 2rem;
}

._button {
  display: flex;
  padding: var(--spacing-sm, 0.75rem) var(--spacing-md, 1rem);
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-md, 0.5rem);
  background: var(--surface-action, #bce670);
  color: var(--text-on-action, #151519);

  /* button/md */
  font-family: var(--type-font-family-secondary, "Exo 2");
  font-size: var(--fontsize-body-md, 1rem);
  font-style: normal;
  font-weight: 600;
  line-height: var(--fontsize-body-md, 1rem); /* 100% */
  text-transform: uppercase;
  margin-top: 2rem;
}

@include responsive(xl) {
  ._content {
    max-width: 61.5rem;
  }
  ._textContainer {
    max-width: 27.3rem;
  }
}

@include responsive(lg) {
  ._container {
    padding: 3rem 0rem;
  }
  ._content {
    max-width: 100%;
    flex-direction: column;
    gap: 2rem;
  }

  ._imageContainer {
    width: 100%;
    max-width: 100%;
  }

  ._textContainer {
    max-width: 100%;
    text-align: center;
    padding: 0 1rem;
  }
  ._title {
    font-size: 2.25rem;
    line-height: normal;
  }

  ._text {
    font-size: 1rem;
    line-height: normal;
  }

  ._textAction {
    font-size: 1.5rem;
    line-height: normal;
  }
}
