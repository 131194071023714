.secondSwiper {
  .swiper-pagination-bullet {
    width: 1rem !important;
    height: 1rem !important;
    background-color: #676b7e !important;
    border: none !important;
  }

  .swiper-pagination-bullet-active {
    background: #8fd511 !important;
  }
}
