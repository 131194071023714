@import "./fonts.scss";
@import url('https://fonts.googleapis.com/css2?family=Denk+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap');

html {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricprecision;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1 {
  letter-spacing: -1%;
  font-weight: 700;
  font-family: $primaryFont;
  margin: 0;
}
h2 {
  letter-spacing: 0%;
  font-weight: 700;
  font-family: $primaryFont;
  margin: 0;
}
h3 {
  letter-spacing: 1%;
  font-weight: 600;
  font-family: $primaryFont;
  margin: 0;
}
h4,
h5,
h6,
p {
  font-family: $secondaryFont;
  margin: 0;
}
