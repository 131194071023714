@import "../../styles/index.scss";

._header {
  display: block;
  z-index: 1300;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #040E2F;
  right: 0;
}

._upperContent {
  width: 100%;
  max-width: 72rem;
  height: auto;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.75rem 0;
}
._upperLeft {
  display: flex;
  height: auto;
}
._sloganHeader {
  font-family: Arial, Verdana, Tahoma;
  font-size: 0.75rem;
  font-weight: 300;
  margin: 0;
  padding: 0;
  color: #C2C2C2;
  margin-top: 0.75rem;
  display: block;
  line-height: normal;
  box-sizing: border-box;
}
._list {
  display: flex;
  list-style: none;
  gap: 5px;
  width: 100%;
  padding: 0;
  padding: 0 1rem;
  max-width: 74rem;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

._element {
  cursor: pointer;
  font-family: Helvetica, Arial, sans-serif;
  padding: 0.9375rem 0;
}

._link {
  display: block;
  color: #fff;
  font-size: 0.8125rem;
  line-height: 0.9375rem;
  border-bottom: 1px solid transparent;
  transition: all 0.3s ease;
  &:hover {
    border-bottom: 1px solid #fff;
  }
}

._logo {
  width: 15.875rem;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: auto;
    height: 100%;
  }
}

._dateContainer {
  margin: 0.75rem 0;
  font-size: 0.875rem;
  display: flex;
  font-family:
    Arial,
    Helvetica Neue,
    Helvetica,
    sans-serif;
  align-items: center;
}
._date {
  color: #308230;
  padding: 0rem 0.4375rem;
}

._line {
  width: 1px;
  height: 16px;
  background: #308230;
  display: block;
}

._textDate {
  color: #C2C2C2;
  padding-left: 0.4375rem;
}

._time {
  color: #cd0707;
  font-weight: 700;
  padding: 0rem 0.4375rem;
}

._socialContainer {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 2.25rem;
}

._social {
  padding-bottom: 0.625rem;
  width: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 4px solid black;
}

._twitter {
  border-color: #39a7fc;
}

._facebook {
  border-color: #1374e2;
}

._instagram {
  border-color: #e5b138;
}

._feed {
  border-color: #eb651e;
}

._youtube {
  border-color: #ed1d19;
}

._nav {
  width: 100%;
  background-color: #515151;
}