@import "../../styles/index.scss";

._imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

._imageSlider {
  width: auto;
  height: 100%;
  position: absolute;
}

._adContainer {
  width: 100%;
  max-width: 55.0625rem;
  margin: 0 auto;
  padding: 1rem 0rem;
}
._imageSliderMobile {
  display: none;
}
@include responsive(lg) {
  ._imageSliderMobile {
    width: auto;
    height: 100%;
    position: absolute;
    display: block;
  }
}
